import { Box } from '@mui/material'
import { ReactComponent as InfoActiveIcon } from 'assets/imgs/marketCap/info-active.svg'
import { ReactComponent as MarketActiveIcon } from 'assets/imgs/marketCap/market-active.svg'
import { StyledInfoIcon, StyledMarketIcon } from 'components/Icons/StyledIcon'
import { PageWrapper } from 'components/PageWrapper'
import Row from 'components/Row'
import { useState } from 'react'
import styled from 'styled-components/macro'

import InfoList from './InfoList'
import MarketList from './MarketList'
import { StyledBorderBox } from './StyledBorderBox'

const StyledTab = styled(StyledBorderBox)`
  width: fit-content;
  display: flex;
  gap: 8px;
  padding: 8px 10px;
`

export default function MarketCap() {
  const [curr, setCurr] = useState(0)

  const tabList = [
    { icon: <StyledMarketIcon />, activeIcon: <MarketActiveIcon /> },
    { icon: <StyledInfoIcon />, activeIcon: <InfoActiveIcon /> },
  ]

  return (
    <PageWrapper className="market-cap">
      <Row justify="flex-end">
        <StyledTab>
          {tabList.map(({ icon, activeIcon }, i) => (
            <Box
              key={i}
              className={`tabItem ${curr == i && 'tabItemActive'}`}
              onClick={() => setCurr(i)}
              style={{
                cursor: 'pointer',
              }}
            >
              {curr == i ? activeIcon : icon}
            </Box>
          ))}
        </StyledTab>
      </Row>
      <Box mt={curr == 0 ? '72px' : '32px'}>{curr == 0 ? <MarketList /> : <InfoList />}</Box>
    </PageWrapper>
  )
}
