import { Box } from '@mui/material'
import styled from 'styled-components/macro'

const StyledChartBox = styled(Box)`
  width: 100%;
  height: 400px;
  border-radius: 16px;
  background: ${({ theme }) => theme.themeBg};
`

export default function ChartSection() {
  return (
    <StyledChartBox>
      <iframe
        height="100%"
        width="100%"
        id="dexscreener-embed"
        title="dexscreener Embed"
        src="https://dexscreener.com/blast/0xE5291eE41639632788737c23724C5AF29D86274B?embed=1&theme=dark&trades=0&info=0"
        allow="clipboard-write"
      ></iframe>
    </StyledChartBox>
  )
}
