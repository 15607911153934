import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import CreateBtnIcon from 'assets/imgs/marketCap/createBtn.svg'
import { ReactComponent as UpLoadIcon } from 'assets/imgs/marketCap/upLoad.svg'
import axios from 'axios'
import { Pending } from 'components/Button'
import Column from 'components/Column'
import { useActiveChainId } from 'connection/useActiveChainId'
import { keccak256, solidityPack } from 'ethers/lib/utils'
import { useLaunchpadContract } from 'hooks/useContract'
import { useCallback, useRef, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { handlerError } from 'utils/formatError'

import { StyledBorderBox } from '../StyledBorderBox'

const StyledCreateBox = styled(StyledBorderBox)`
  width: 500px;
  padding: 16px;
`

const Input = styled.input<{ error?: boolean }>`
  font-size: 16px;
  outline: none;
  flex: 1 1 auto;
  width: 0;
  height: 48px;
  background-color: transparent;
  border: 2px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 8px;
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  color: ${({ error, theme }) => (error ? theme.accentFailure : theme.textPrimary)};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 700;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.textTertiary};
    font-weight: 400;
  }
  padding: 16px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

const StyledUpLoad = styled(Box)`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: ${({ theme }) => theme.backgroundInteractive};
  border: 2px solid ${({ theme }) => theme.primaryBorder};
  border-radius: 8px;
`

const StyledCreateBtn = styled(Box)`
  width: 468px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${CreateBtnIcon});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`

const getTokenKey = (account: string, name: string, symbol: string) => {
  const tokenKey = keccak256(solidityPack([`address`, `string`, `string`], [account, name, symbol]))
  return tokenKey
}

export default function Create() {
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'

  const [imgUrl, setImgUrl] = useState('')
  const [nameInput, setNameInput] = useState('')
  const [tickerInput, setTickerInput] = useState('')
  const [descInput, setDescInput] = useState('')
  const [twitterInput, setTwitterInput] = useState('')
  const [telegramInput, setTelegramInput] = useState('')
  const [websiteInput, setWebsiteInput] = useState('')
  const handleNameInput = (e: any) => {
    setNameInput(e.target.value)
  }
  const handleTickerInput = (e: any) => {
    setTickerInput(e.target.value)
  }
  const handleDescInput = (e: any) => {
    setDescInput(e.target.value)
  }
  const handleTwitterInput = (e: any) => {
    setTwitterInput(e.target.value)
  }
  const handleTelegramInput = (e: any) => {
    setTelegramInput(e.target.value)
  }
  const handleWebsiteInput = (e: any) => {
    setWebsiteInput(e.target.value)
  }

  const { account } = useActiveChainId()

  const inputRef = useRef(null)
  const handlerUpload = () => {
    // if (!inputRef.current) return
    // inputRef?.current?.click()
  }

  const handleFileChange = async (event: any) => {
    const fileImg = event.target.files[0]
    if (!fileImg || !account) return
    const formData = new FormData()
    formData.append('file', fileImg)
    try {
      const response = await axios.post(`https://luanchpadtest.roguex.io/upload/${account?.toLowerCase()}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      console.log(response.data.data, 'response')
      setImgUrl(response.data.data)
    } catch (error) {
      console.error('Upload Error :', error)
    }
  }

  const handlerCreateToken = useCallback(async () => {
    if (!account || !nameInput || !tickerInput) return
    const tokenKey = await getTokenKey(account, nameInput, tickerInput)

    if (!imgUrl || !tokenKey || !descInput || !twitterInput || !telegramInput || !websiteInput) {
      return
    }

    const formData = new FormData()
    formData.append('image_url', imgUrl)
    formData.append('token_key', tokenKey)
    formData.append('description', descInput)
    formData.append('twitter', twitterInput)
    formData.append('telegram', telegramInput)
    formData.append('website', websiteInput)

    const parmasData = {
      image_url: imgUrl,
      token_key: tokenKey,
      description: descInput,
      twitter: twitterInput,
      telegram: telegramInput,
      website: websiteInput,
    }

    try {
      const response = await axios.post(' https://luanchpadtest.roguex.io/create_token', parmasData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      console.log(response.data, 'response')
      if (response.data.code == 200) {
        console.log('Create success!')
      }
    } catch (error) {
      console.error('Create Error :', error)
    }
  }, [account, descInput, imgUrl, nameInput, telegramInput, tickerInput, twitterInput, websiteInput])

  const LaunchpadContract = useLaunchpadContract()

  const addTransaction = useTransactionAdder()
  const handlerCreate = useCallback(async () => {
    if (!LaunchpadContract || !nameInput || !tickerInput) return

    setAttemptingTxn(true)

    try {
      const response = await LaunchpadContract.createTokenPool(nameInput, tickerInput, { value: 1 })

      await handlerCreateToken()
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.CREATE,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('create  error', error)
    }
  }, [LaunchpadContract, addTransaction, handlerCreateToken, nameInput, tickerInput])

  return (
    <StyledCreateBox>
      <Column gap="16px">
        <Column align="flex-start" gap="8px" width="100%">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Name</Trans>
          </ThemedText.TextSecondary>
          <Input
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="Name"
            onChange={handleNameInput}
            value={nameInput}
          />
        </Column>
        <Column align="flex-start" gap="8px" width="100%">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Ticker</Trans>
          </ThemedText.TextSecondary>
          <Input
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="Ticker"
            onChange={handleTickerInput}
            value={tickerInput}
          />
        </Column>
        <Column align="flex-start" gap="8px" width="100%">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Description</Trans>
          </ThemedText.TextSecondary>
          <Input
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="Description"
            onChange={handleDescInput}
            value={descInput}
          />
        </Column>
        <Column align="flex-start" gap="8px" width="100%">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Image</Trans>
          </ThemedText.TextSecondary>
          <input type="file" onChange={handleFileChange} ref={inputRef} />
          {/* style={{ display: 'none' }} /> */}
          <StyledUpLoad>
            <UpLoadIcon />
            <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
              <Trans>Upload</Trans>
            </ThemedText.TextPrimary>
          </StyledUpLoad>
        </Column>
        <Column align="flex-start" gap="8px" width="100%">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Twitter</Trans>
          </ThemedText.TextSecondary>
          <Input
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="Twitter"
            onChange={handleTwitterInput}
            value={twitterInput}
          />
        </Column>
        <Column align="flex-start" gap="8px" width="100%">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Telegram</Trans>
          </ThemedText.TextSecondary>
          <Input
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="Telegram"
            onChange={handleTelegramInput}
            value={telegramInput}
          />
        </Column>
        <Column align="flex-start" gap="8px" width="100%">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Website</Trans>
          </ThemedText.TextSecondary>
          <Input
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="Website"
            onChange={handleWebsiteInput}
            value={websiteInput}
          />
        </Column>
        <Box mt="10px">
          <StyledCreateBtn onClick={handlerCreate}>
            <ThemedText.TextPrimary color="white" fontSize={16} fontWeight={700}>
              <Trans>{attemptingTxn ? <Pending /> : 'Create'}</Trans>
            </ThemedText.TextPrimary>
          </StyledCreateBtn>
        </Box>
      </Column>
    </StyledCreateBox>
  )
}
