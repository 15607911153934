import { useActiveChainId } from 'connection/useActiveChainId'
import { useToken } from 'hooks/Tokens'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useMemo } from 'react'
import { BN, fromSqrt96Wei } from 'utils/bn'

import { usePoolSqrtRatioX96 } from './InfoDetail/hooks'

export const usePoolLiquidity = (poolAddress: string, tokenAddress: string) => {
  const { chainId } = useActiveChainId()
  const token = useToken(tokenAddress)
  const tokenBalance = useTokenBalance(poolAddress, token ?? undefined)
  const nativeCurrency = useNativeCurrency(chainId)
  const wethBalance = useTokenBalance(poolAddress, nativeCurrency.wrapped ?? undefined)

  return useMemo(() => {
    if (!tokenBalance || !wethBalance)
      return {
        tokenBalance: undefined,
        wethBalance: undefined,
      }
    return {
      tokenBalance,
      wethBalance,
    }
  }, [tokenBalance, wethBalance])
}

export const useTokenMarketCap = (poolAddress: string, isToken0: boolean) => {
  const tokenSupply = BN(1000000000)
  const poolSqrtRatioX96 = usePoolSqrtRatioX96(poolAddress)
  return useMemo(() => {
    if (!poolSqrtRatioX96) return
    const price = fromSqrt96Wei(poolSqrtRatioX96.toString())
    if (isToken0) {
      return tokenSupply.multipliedBy(price)
    } else {
      return tokenSupply.multipliedBy(BN(1).div(price))
    }
  }, [tokenSupply, poolSqrtRatioX96])
}
