import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as CopyIcon } from 'assets/imgs/marketCap/copy.svg'
import { ReactComponent as UserIcon } from 'assets/imgs/marketCap/user.svg'
import Column from 'components/Column'
import { MediumLoadingBubble } from 'components/Loading'
import Row from 'components/Row'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { countZeros } from 'utils/countZeros'

import { usePoolLiquidity, useTokenMarketCap } from './hooks'
import { LinkBox2 } from './LinkBox'
import { StyledBorderBox } from './StyledBorderBox'
import { useHttpLaunchpadListData } from 'state/http/hooks'
import { computeNumUnit } from 'utils/formatNum'

const StyledMarketList = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
`

const StyledMarketItem = styled(StyledBorderBox)`
  padding: 16px;
  .desc {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export function LoadingRows() {
  return (
    <>
      <MediumLoadingBubble />
      <MediumLoadingBubble />
      <MediumLoadingBubble />
      <MediumLoadingBubble />
      <MediumLoadingBubble />
      <MediumLoadingBubble />
    </>
  )
}

export default function MarketList() {
  const { data, loading } = useHttpLaunchpadListData()

  return (
    <StyledMarketList>
      {loading ? <LoadingRows /> : data?.map((item: any, i: number) => <MarketItem item={item} key={i} />)}
    </StyledMarketList>
  )
}

function MarketItem({ item }: any) {
  const { wethBalance, tokenBalance } = usePoolLiquidity(item.launchPoolInfo.pool.id, item.address)
  const marketCap = useTokenMarketCap(item.launchPoolInfo.pool.id, item.launchPoolInfo.isToken0)

  return (
    <NavLink to={`/infoDetail?token=${item.address}`} style={{ textDecoration: 'none' }}>
      <StyledMarketItem>
        <Box display="flex" alignItems="center" gap="14px" justifyContent="flex-start" width="100%">
          <img src={item?.metadata?.image_url} width="78px" height="78px" />
          <Column gap="8px" align="flex-start" justify="center">
            <Row gap="4px">
              <ThemedText.TextPrimary fontSize={18} fontWeight={800}>
                <Trans>{item.symbol}</Trans>
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                <Trans>{item.name}</Trans>
              </ThemedText.TextSecondary>
            </Row>
            <Row gap="8px">
              <ThemedText.TextPrimary fontSize={12} fontWeight={400}>
                <Trans>{shortenAddress(item.address)}</Trans>
              </ThemedText.TextPrimary>
              <CopyIcon />
            </Row>
            <Row gap="4px">
              <UserIcon />
              <ThemedText.TextPrimary fontSize={12} fontWeight={400}>
                <Trans>{shortenAddress(item.createdOrigin)}</Trans>
              </ThemedText.TextPrimary>
            </Row>
          </Column>
        </Box>
        <Box display="flex" alignItems="flex-start" gap="8px" justifyContent="space-between" width="100%" mt="16px">
          <Column gap="8px" align="flex-start">
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              <Trans>Market Cap</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontSize={24} fontWeight={700}>
              {countZeros(marketCap?.toFixed())} ETH
            </ThemedText.TextPrimary>
          </Column>
          <Column gap="6px" align="flex-end">
            <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
              <Trans>Liquidity</Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
              {computeNumUnit(wethBalance?.toExact())} ETH
            </ThemedText.TextPrimary>
            <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
              {computeNumUnit(tokenBalance?.toExact())} {item.symbol}
            </ThemedText.TextPrimary>
            {/* <Box width={200}>
            <CapProgressBar value={20} />
          </Box> */}
          </Column>
        </Box>
        <ThemedText.TextSecondary fontSize={12} fontWeight={400} mt="16px" className="desc">
          <Trans>{item?.metadata?.description}</Trans>
        </ThemedText.TextSecondary>
        <Box mt="18px">
          <LinkBox2 metadata={item?.metadata} />
        </Box>
      </StyledMarketItem>
    </NavLink>
  )
}
