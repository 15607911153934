import { useActiveChainId } from 'connection/useActiveChainId'
import { BigNumber } from 'ethers'
import { Interface } from 'ethers/lib/utils'
import { useQuoter, useSwapRouterContract, useV3PoolContract } from 'hooks/useContract'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import { useSingleCallResult } from 'lib/hooks/multicall'
import React, { useCallback, useMemo } from 'react'
import { BN } from 'utils/bn'
import SwapRouterABI from 'abis/SwapRouter.json'
import { SWAP_ROUTER_ADDRESSES } from 'constants/addresses'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { Multicall } from '@uniswap/v3-sdk'
export const usePoolSqrtRatioX96 = (poolAddress: string) => {
    const v3PoolContract = useV3PoolContract(poolAddress)

    const { result: slot0 } = useSingleCallResult(v3PoolContract, 'slot0')

    const currentSqrtRatioX96: undefined | BigNumber = useMemo(() => slot0?.[0], [slot0])

    return currentSqrtRatioX96
}


export const useDoSwap = () => {
    const INTERFACE: Interface = new Interface(SwapRouterABI.abi)

    // struct ExactInputSingleParams {
    //     address tokenIn;
    //     address tokenOut;
    //     uint24 fee;
    //     address recipient;
    //     uint256 deadline;
    //     uint256 amountIn;
    //     uint256 amountOutMinimum;
    //     uint160 sqrtPriceLimitX96;
    // }
    const { account, provider, chainId } = useActiveChainId()
    const DoSwap = useCallback(async (tokenIn: string, tokenOut: string, amountIn: string, amountOutMin: any, InputETH: boolean) => {
        if (!account || !provider || !chainId) return
        const calldatas: string[] = []
        const params = {
            tokenIn,
            tokenOut,
            fee: BN(100).toFixed(),
            recipient: account,
            deadline: Math.round(Number(new Date()) / 1000) + 5000,
            amountIn,
            amountOutMinimum: amountOutMin,
            sqrtPriceLimitX96: '0'
        }
        console.log({ params })
        const add_data = INTERFACE.encodeFunctionData('exactInputSingle', [
            params
        ])
        calldatas.push(add_data)
        if (InputETH) {
            const refundETH_data = INTERFACE.encodeFunctionData('refundETH')
            calldatas.push(refundETH_data)
        }
        const txn: { to: string; data: string; value: string } = {
            to: SWAP_ROUTER_ADDRESSES[chainId],
            data: Multicall.encodeMulticall(calldatas),
            value: InputETH ? amountIn : '0',
        }
        provider
            .getSigner()
            .estimateGas(txn)
            .then((estimate) => {
                const newTxn = {
                    ...txn,
                    gasLimit: calculateGasMargin(estimate) || 4000000,
                }
                return provider
                    .getSigner()
                    .sendTransaction(newTxn)
                    .then((response) => {
                    })
            })
            .catch((error) => {
                console.error('Failed to send transaction', error)
                // we only care if the error is something _other_ than the user rejected the tx
                if (error?.code !== 4001) {
                    console.error(error)
                }
            })
    }, [chainId, provider, account])

    return { DoSwap }
}


