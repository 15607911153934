import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { PageWrapper } from 'components/PageWrapper'
import { useLaunchpadDataByToken } from 'graphql/thegraph/LaunchpadQuery'
import { useLocation } from 'react-router-dom'
import { ThemedText } from 'theme'

import ChartSection from './ChartSection'
import HolderList from './HolderList'
import Info from './Info'
import Swap from './Swap'
import TradeList from './TradeList'

export default function InfoDetail() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')

  const data = useLaunchpadDataByToken(token ?? undefined)

  return (
    <PageWrapper className="info-detail">
      {data && (
        <Box display="flex" alignItems="flex-start" width="100%" gap="24px">
          <div className="flex-1">
            <ChartSection />
            <Box m="16px 0">
              <ThemedText.TextSecondary fontSize={16} fontWeight={700}>
                <Trans>Trade</Trans>
              </ThemedText.TextSecondary>
            </Box>
            <TradeList infoData={data[0]} />
          </div>
          <Box display="flex" flexDirection="column" alignItems="flex-start" gap="16px" width="340px">
            <Swap infoData={data[0]} />
            <Info token={token} />
            <HolderList infoData={data[0]} />
          </Box>
        </Box>
      )}
    </PageWrapper>
  )
}
