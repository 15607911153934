import { Box } from '@mui/material'
import { ReactComponent as BrowserIcon } from 'assets/imgs/marketCap/browser.svg'
import { ReactComponent as DiscordIcon } from 'assets/imgs/marketCap/discord.svg'
import { ReactComponent as TelegramIcon } from 'assets/imgs/marketCap/telegram.svg'
import { ReactComponent as TwitterIcon } from 'assets/imgs/marketCap/twitter.svg'
import styled from 'styled-components/macro'

const StyledLinkBox = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6px;
  .link-item {
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`

export default function LinkBox({ metadata }: any) {
  return (
    <Box display="flex" alignItems="center" gap="8px">
      <a href={metadata?.website} target="_blank" className="link-item" rel="noreferrer">
        <BrowserIcon />
      </a>
      <a href={metadata?.twitter} target="_blank" className="link-item" rel="noreferrer">
        <TwitterIcon />
      </a>
      <a href={metadata?.website} target="_blank" className="link-item" rel="noreferrer">
        <DiscordIcon />
      </a>
      <a href={metadata?.telegram} target="_blank" className="link-item" rel="noreferrer">
        <TelegramIcon />
      </a>
    </Box>
  )
}

export function LinkBox2({ metadata }: any) {
  return (
    <StyledLinkBox>
      <a href={metadata?.website} target="_blank" className="link-item" rel="noreferrer">
        <BrowserIcon />
      </a>
      <a href={metadata?.twitter} target="_blank" className="link-item" rel="noreferrer">
        <TwitterIcon />
      </a>
      <a href={metadata?.website} target="_blank" className="link-item" rel="noreferrer">
        <DiscordIcon />
      </a>
      <a href={metadata?.telegram} target="_blank" className="link-item" rel="noreferrer">
        <TelegramIcon />
      </a>
    </StyledLinkBox>
  )
}
