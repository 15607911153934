import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { Currency, Percent } from '@uniswap/sdk-core'
import { CurrencyAmount } from '@uniswap/sdk-core'
import QUOTERABI from 'abis/quoter.json'
import TradeBtn from 'assets/imgs/marketCap/tradeBtn.svg'
import { useDynamicApprove } from 'components/DynamicApprove'
import MaxTab from 'components/MaxTab'
import NumericalInput from 'components/NumericalInput'
import { SettingsTab2 } from 'components/Settings'
import { useActiveChainId } from 'connection/useActiveChainId'
import { SWAP_ROUTER_ADDRESSES } from 'constants/addresses'
import { useCurrency } from 'hooks/Tokens'
import { useQuoter } from 'hooks/useContract'
import useCurrencyBalance from 'lib/hooks/useCurrencyBalance'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, fromWei, toWei } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'
import { useContractRead } from 'wagmi'

import { StyledBorderBox } from '../StyledBorderBox'
import { useDoSwap } from './hooks'
import BigNumber from 'bignumber.js'

const StyledSwapBox = styled(StyledBorderBox)`
  padding: 16px;
`

const StyledTab = styled(Box)`
  flex: 1;
  padding: 2px;
  border-radius: 8px;
  height: 40px;
  background: ${({ theme }) => theme.backgroundInteractive};
  display: flex;
  .tab {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  .tabActive-buy {
    background: ${({ theme }) => theme.long};
  }
  .tabActive-sell {
    background: ${({ theme }) => theme.short};
  }
`

const StyledInputTab = styled(Box)`
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  .tab-item {
    height: 30px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.primaryBorder};
  }
`

const StyledInputBox = styled(Box)`
  margin-top: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.primaryBorder};
  padding: 16px;
`
const StyledTradeBtn = styled(Box)`
  width: 308px;
  height: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${TradeBtn});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`
export const numberToHex = (val: BigNumber.Value, decimals = 18) => {
  const num_str = BigNumber(val).multipliedBy(BigNumber(10).pow(decimals));
  const x = new BigNumber(num_str);
  return x.toFixed(0);
};

export default function Swap({ infoData }: any) {
  const [curr, setCurr] = useState(0)
  const [swapAmount, setSwapAmount] = useState('')
  const [receiveValue, setReceive] = useState(0)
  const currList = ['BUY', 'SELL']
  const [spreadL, setSpreadL] = useState<Percent>(new Percent(10, 100))
  // const isToken0: boolean = infoData.launchPoolInfo.isToken0
  const { chainId, account } = useActiveChainId()
  const native = useNativeCurrency(chainId)
  const ethBalance = useCurrencyBalance(account ?? undefined, native)
  const inputTabList = [
    { val: 1, desc: 1 },
    { val: 10, desc: 10 },
    { val: 100, desc: 100 },
    { val: 1000, desc: '1k' },
  ]
  const onUserInput = (amount: any) => {
    setSwapAmount(amount)
  }

  const QuoterContract = useQuoter()
  const { DoSwap } = useDoSwap()

  const tokenCurrency = useCurrency(infoData.address)
  const tokenBalance = useCurrencyBalance(account ?? undefined, tokenCurrency ?? undefined)

  const amountCurrency: CurrencyAmount<Currency> | undefined = useMemo(() => {
    if (!tokenCurrency || !swapAmount) return undefined
    return CurrencyAmount.fromRawAmount(tokenCurrency, numberToHex(swapAmount))
  }, [tokenCurrency, swapAmount])

  const { DynamicApprove, isApproved } = useDynamicApprove(
    [amountCurrency],
    SWAP_ROUTER_ADDRESSES[chainId] ?? undefined
  )

  const quoteParams = useMemo(() => {
    if (!swapAmount || swapAmount == '0' || swapAmount == '') return undefined
    if (curr == 0) {
      return [native.wrapped.address, infoData.address, '100', numberToHex(swapAmount), '0']
    } else {
      return [infoData.address, native.wrapped.address, '100', numberToHex(swapAmount), '0']
    }
  }, [curr, infoData.address, native.wrapped.address, swapAmount])

  //
  // function quoteExactInputSingle(
  //     address tokenIn,
  //     address tokenOut,
  //     uint24 fee,
  //     uint256 amountIn,
  //     uint160 sqrtPriceLimitX96
  // ) public override returns (uint256 amountOut)
  //
  const { data: esreceiveAmount }: any = useContractRead({
    address: QuoterContract?.address as any,
    abi: QUOTERABI.abi,
    functionName: 'quoteExactInputSingle',
    args: quoteParams as any,
  })

  // const { result, error } = useSingleCallResult(QuoterContract ?? undefined, 'quoteExactInputSingle', quoteParams ?? [undefined])
  const handleBuy = async () => {
    if (curr == 0) {
      await DoSwap(
        native.wrapped.address,
        infoData.address,
        numberToHex(swapAmount),
        esreceiveAmount && spreadL ? BN(esreceiveAmount).multipliedBy(BN(100).minus(spreadL.toFixed(2))).div(100).toFixed(0) : '0',
        true
      )
    } else {
      if (isApproved) {
        await DoSwap(
          infoData.address,
          native.wrapped.address,
          numberToHex(swapAmount),
          esreceiveAmount && spreadL ? BN(esreceiveAmount).multipliedBy(BN(100).minus(spreadL.toFixed(2))).div(100).toFixed(0) : '0',
          false
        )
      }
    }
  }

  const onUserMaxTab = useCallback((value: number | string) => {
    const selectedCurrencyBalance = curr == 0 ? ethBalance : tokenBalance
    if (!selectedCurrencyBalance) return
    const max = BN(selectedCurrencyBalance.toExact()).multipliedBy(value).toFixed(18)
    onUserInput(max)
  }, [ethBalance, tokenBalance, curr])

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <StyledSwapBox>
        <Box display="flex" alignItems="center" width="100%" gap="8px">
          <StyledTab>
            {currList.map((item, i) => (
              <Box
                key={i}
                className={`tab flex-1 ${curr == i && curr == 0 && 'tabActive-buy'} ${curr == i && curr == 1 && 'tabActive-sell'
                  }`}
                onClick={() => setCurr(i)}
                style={{
                  cursor: 'pointer',
                }}
              >
                {curr == i ? (
                  <ThemedText.TextPrimary color="white" fontSize={14} fontWeight={700}>
                    <Trans>{item}</Trans>
                  </ThemedText.TextPrimary>
                ) : (
                  <ThemedText.TextSecondary fontSize={14} fontWeight={700}>
                    <Trans>{item}</Trans>
                  </ThemedText.TextSecondary>
                )}
              </Box>
            ))}
          </StyledTab>
          <SettingsTab2 autoSlippage={spreadL} chainId={chainId} trade={undefined} setFunc={setSpreadL} />
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt="16px">
          {/* <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Switch to RFD</Trans>
          </ThemedText.TextSecondary> */}
          {/* <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>SET Max Slippage</Trans>
          </ThemedText.TextSecondary> */}
        </Box>
        <StyledInputBox>
          <Box display="flex" justifyContent="flex-end">
            <ThemedText.TextSecondary fontSize={14} fontWeight={400} mb="14px">
              Balance:{formatAmount(curr == 0 ? ethBalance?.toFixed() : tokenBalance?.toFixed(), 4, true)}{' '}
              {curr == 0 ? 'ETH' : infoData.symbol}
            </ThemedText.TextSecondary>
          </Box>
          <NumericalInput value={swapAmount} onUserInput={onUserInput} />
          <Box>
            <MaxTab onChange={onUserMaxTab} />
          </Box>
        </StyledInputBox>
        {/* <StyledInputTab>
          {inputTabList.map((tab, i) => (
            <Box display="flex" justifyContent="center" alignItems="center" key={i} className="tab-item">
              <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                <Trans>{tab.desc} USDB</Trans>
              </ThemedText.TextSecondary>
            </Box>
          ))}
        </StyledInputTab> */}
        <Box display="flex" justifyContent="space-between" alignItems="center" m="16px 0">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Receive</Trans>
          </ThemedText.TextSecondary>
          <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
            {formatAmount(fromWei(esreceiveAmount), 4, true)} {curr == 1 ? 'ETH' : infoData.symbol}
          </ThemedText.TextPrimary>
        </Box>
        {curr == 1 && !isApproved && <DynamicApprove />}
        <Box mt="16px" onClick={handleBuy}>
          <StyledTradeBtn>
            <ThemedText.TextPrimary color="white" fontSize={16} fontWeight={700}>
              <Trans>Trade</Trans>
            </ThemedText.TextPrimary>
          </StyledTradeBtn>
        </Box>
      </StyledSwapBox>
    </Box>
  )
}
