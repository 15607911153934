import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as ParticipantsIcon } from 'assets/imgs/marketCap/participants.svg'
import Column from 'components/Column'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { countZeros } from 'utils/countZeros'

import { formatAmount } from '../../utils/formatAmout'
import { usePoolLiquidity, useTokenMarketCap } from './hooks'
import LinkBox from './LinkBox'
import { LoadingRows } from './MarketList'
import { StyledBorderBox } from './StyledBorderBox'
import { useHttpLaunchpadListData } from 'state/http/hooks'

const StyledInfoList = styled(Box)``

const StyledInfoRow = styled(StyledBorderBox)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 140px 140px 200px 120px 1.5fr;
`
const StyledTrRow = styled(StyledInfoRow)`
  padding: 12px;
`
const StyledThRow = styled(StyledInfoRow)`
  padding: 20px 12px;
  .desc {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export default function InfoList() {
  const { data, loading } = useHttpLaunchpadListData()

  return (
    <StyledInfoList>
      <StyledTrRow>
        <Box p="0 12px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Info</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box p="0 12px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Liquidity</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box p="0 12px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Marketcap</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box p="0 12px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Participants</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box display="flex" justifyContent="flex-end" p="0 12px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Created in</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box p="0 12px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>Description</Trans>
          </ThemedText.TextSecondary>
        </Box>
      </StyledTrRow>
      <Box mt="16px" width="100%">
        {loading ? (
          <Column gap="16px" width="100%">
            <LoadingRows />
          </Column>
        ) : (
          <Column gap="16px">
            {data?.map((item: any, i: number) => (
              <InfoItem item={item} key={i} />
            ))}
          </Column>
        )}
      </Box>
    </StyledInfoList>
  )
}

function InfoItem({ item }: any) {
  const { wethBalance, tokenBalance } = usePoolLiquidity(item.launchPoolInfo.pool.id, item.address)
  const marketCap = useTokenMarketCap(item.launchPoolInfo.pool.id, item.launchPoolInfo.isToken0)

  const createdTime = Math.floor(Number(new Date()) / 1000) - Number(item.createdAtTimestamp)

  let d: any = Math.floor(createdTime / 60 / 60 / 24)
  d = d < 10 ? '0' + d : d
  let h: any = Math.floor((createdTime / 60 / 60) % 24)
  h = h < 10 ? '0' + h : h
  let m: any = Math.floor((createdTime / 60) % 60)
  m = m < 10 ? '0' + m : m

  return (
    <NavLink to={`/infoDetail?token=${item.address}`} style={{ textDecoration: 'none', width: '100%' }}>
      <StyledThRow>
        <Box display="flex" gap="16px" p="0 12px">
          <img src={item?.metadata?.image_url} width="64px" height="64px" />
          <Column align="flex-start">
            <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
              <Trans>{item.symbol}</Trans>
            </ThemedText.TextPrimary>
            <Box mt="8px">
              <LinkBox metadata={item?.metadata} />
            </Box>
            <ThemedText.TextPrimary fontSize={12} fontWeight={400} mt="12px">
              <Trans>{shortenAddress(item.address)}</Trans>
            </ThemedText.TextPrimary>
          </Column>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" gap="8px" p="0 12px">
          <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
            {countZeros(wethBalance?.toExact())} ETH
          </ThemedText.TextPrimary>
          <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
            {countZeros(tokenBalance?.toExact())} {item.symbol}
          </ThemedText.TextPrimary>
        </Box>
        <Box display="flex" alignItems="center" p="0 12px">
          <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
            {countZeros(marketCap?.toFixed())} ETH
          </ThemedText.TextPrimary>
        </Box>
        <Box display="flex" alignItems="center" gap="8px" p="0 12px">
          <ParticipantsIcon />
          <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
            {formatAmount(item.userCount, 0, true)}
          </ThemedText.TextPrimary>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="center" gap="4px" p="0 12px">
          <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
            {d}D {h}H {m}M
          </ThemedText.TextPrimary>
        </Box>
        <Box p="0 12px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={400} className="desc">
            <Trans>{item?.metadata?.description}</Trans>
          </ThemedText.TextSecondary>
        </Box>
      </StyledThRow>
    </NavLink>
  )
}
