import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as CopyIcon } from 'assets/imgs/marketCap/copy.svg'
import { ReactComponent as UserIcon } from 'assets/imgs/marketCap/user.svg'
import Column from 'components/Column'
import Row from 'components/Row'
import { useLaunchpadQuery } from 'graphql/thegraph/LaunchpadQuery'
import { ThemedText } from 'theme'
import { shortenAddress } from 'utils'

import { LinkBox2 } from '../LinkBox'

export default function Info({ token }: any) {
  const { data, loading } = useLaunchpadQuery()
  const currInfo = data?.filter((item: any) => item.address === token)

  return (
    <Column align="flex-start">
      <Box display="flex" alignItems="flex-start" gap="14px" justifyContent="flex-start" width="100%">
        <img src={currInfo[0].metadata.image_url} width="100px" height="100px" />
        <Column gap="8px" align="flex-start" justify="center">
          <Row gap="4px">
            <ThemedText.TextPrimary fontSize={18} fontWeight={800}>
              <Trans>{currInfo[0].symbol}</Trans>
            </ThemedText.TextPrimary>
            <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
              <Trans>{currInfo[0].name}</Trans>
            </ThemedText.TextSecondary>
          </Row>
          <Row gap="8px">
            <ThemedText.TextPrimary fontSize={12} fontWeight={400}>
              <Trans>{shortenAddress(currInfo[0].address)}</Trans>
            </ThemedText.TextPrimary>
            <CopyIcon />
          </Row>
          <Row gap="4px">
            <UserIcon />
            <ThemedText.TextPrimary fontSize={12} fontWeight={400}>
              <Trans>{shortenAddress(currInfo[0].createdOrigin)}</Trans>
            </ThemedText.TextPrimary>
          </Row>
        </Column>
      </Box>
      <Row mt="16px">
        <LinkBox2 metadata={currInfo[0].metadata} />
      </Row>
      {/* <ThemedText.TextSecondary fontSize={12} fontWeight={400} mt="16px">
        <Trans>Bonding Curve Progress:</Trans>
      </ThemedText.TextSecondary>
      <ThemedText.TextPrimary fontSize={14} fontWeight={700} mt="6px" className="active-gradient-text">
        41.85k
      </ThemedText.TextPrimary>
      <Box width={300}>
        <BondingProgressBar value={20} />
      </Box> */}
      <ThemedText.TextSecondary fontSize={12} fontWeight={400} mt="28px" className="desc">
        <Trans>{currInfo[0].metadata.description}</Trans>
      </ThemedText.TextSecondary>
    </Column>
  )
}
