import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { useActiveChainId } from 'connection/useActiveChainId'
import dayjs from 'dayjs'
import { useSwapHistoryQuery } from 'graphql/thegraph/LaunchpadQuery'
import { useMemo } from 'react'
import styled from 'styled-components/macro'
import { ExternalLink, ThemedText } from 'theme'
import { ellipseAddressAdd0x, shortenAddress } from 'utils'
import { countZeros } from 'utils/countZeros'
import { computeNumUnit } from 'utils/formatNum'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

const StyledTradeList = styled(Box)``

const StyledTradeRow = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 1.2fr 1fr 1fr 1fr 1fr 1.3fr;
  border-radius: 8px;
  background: ${({ theme }) => theme.themeBg};
`
const StyledTrRow = styled(StyledTradeRow)`
  padding: 16px 8px;
`
const StyledThRow = styled(StyledTradeRow)`
  margin-top: 8px;
  padding: 16px 8px;
`

export default function TradeList({ infoData }: any) {
  const { data: historyList } = useSwapHistoryQuery(infoData.launchPoolInfo.pool.id)
  const { chainId } = useActiveChainId()
  const isToken0: Boolean = infoData.launchPoolInfo.isToken0;


  return (
    <StyledTradeList>
      <StyledTrRow>
        <Box p="0 8px">
          <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
            <Trans>Account</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box p="0 8px">
          <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
            <Trans>Type</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box display="flex" justifyContent="flex-end" p="0 8px">
          <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
            <Trans>ETH</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box display="flex" justifyContent="flex-end" p="0 8px">
          <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
            <Trans>{infoData.symbol}</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box display="flex" justifyContent="flex-end" p="0 8px">
          <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
            <Trans>Date</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box display="flex" justifyContent="flex-end" p="0 8px">
          <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
            <Trans>Transaction</Trans>
          </ThemedText.TextSecondary>
        </Box>
      </StyledTrRow>
      {historyList && historyList.map((item: any) => {
        return <StyledThRow>
          <Box p="0 8px">
            <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
              {shortenAddress(item.recipient)}
            </ThemedText.TextPrimary>
          </Box>
          <Box p="0 8px">
            <ThemedText.TextPrimary color={(item.type == 0) ? "long" : "short"} fontSize={14} fontWeight={700}>
              <Trans>{(item.type == 0) ? "BUY" : "SELL"}</Trans>
            </ThemedText.TextPrimary>
          </Box>
          <Box display="flex" justifyContent="flex-end" p="0 8px">
            <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
            {computeNumUnit(Math.abs(isToken0 ? item.amount1 : item.amount0))}
            </ThemedText.TextPrimary>
          </Box>
          <Box display="flex" justifyContent="flex-end" p="0 8px">
            <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
              {computeNumUnit(Math.abs(!isToken0 ? item.amount1 : item.amount0))}
            </ThemedText.TextPrimary>
          </Box>
          <Box display="flex" justifyContent="flex-end" p="0 8px">
            <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
              <Trans>{dayjs(Number(item.timestamp) * 1000).format('YYYY-MM-DD')}</Trans>
            </ThemedText.TextPrimary>
          </Box>
          <Box display="flex" justifyContent="flex-end" p="0 8px">
            <ExternalLink
              href={getExplorerLink(chainId, item.transaction, ExplorerDataType.TRANSACTION)}
            >
              <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                <Trans> {ellipseAddressAdd0x(item.transaction)}</Trans>
              </ThemedText.TextPrimary>
            </ExternalLink>

          </Box>
        </StyledThRow>
      })}
    </StyledTradeList>
  )
}
